import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import { Button } from '@material-ui/core'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import BlockContent from '../components/BlockContent'
import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 50
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    color: '#393939',
    fontWeight: 900,
    fontFamily: 'Montserrat'
  },
  subheading: {
    fontSize: theme.typography.pxToRem(18),
    color: '#393939',
    fontWeight: 400,
    fontFamily: 'Montserrat'
  }
}))

export default props => {
  const { data } = props
  const { sanityAgenda: agenda } = data
  const classes = useStyles()

  return (
    <Layout>
      <SEO
        title={`${agenda.pageTitle} | Afwerx Engage Space Virtual Showcase`}
        keywords={agenda.seo.keywords}
        description={agenda.seo.description}
        author={agenda.seo.author}
        image={agenda.seo.seo_image.asset.url}
        url={`https://engage.space/${agenda.slug.current}`}
      />
      <Hero
        title="Agenda"
        className="exhibitor-page"
        img={require('../assets/imgs/agenda-01.jpg')}
      />
      <section className="container intro-text page--agenda">
        <div className={classes.root}>
          <h1 className="color--text-primary">
            {agenda.day_section[0].section_title}
          </h1>
          <p>(Schedule is Subject to Change)</p>
          {notNilOrEmpty(agenda.day_section) &&
            mapIndexed((item, index) => {
              return (
                <Accordion className="accordion-container">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {item.title}
                      <br />
                      <span className={classes.subheading}>
                        {item.subtitle}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      ---
                      <br />
                      <BlockContent blocks={item._rawContent} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })(agenda.day_section[0].day)}
        </div>
        <div className={classes.root}>
          <h1 className="color--text-primary">
            {agenda.day_section[1].section_title}
          </h1>
          <p>(Schedule is Subject to Change)</p>
          {notNilOrEmpty(agenda.day_section) &&
            mapIndexed((item, index) => {
              return (
                <Accordion className="accordion-container">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {item.title}
                      <br />
                      <span className={classes.subheading}>
                        {item.subtitle}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      ---
                      <br />
                      <BlockContent blocks={item._rawContent} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })(agenda.day_section[1].day)}
        </div>
      </section>
    </Layout>
  )
}

export const agendaQuery = graphql`
  query {
    sanityAgenda {
      pageTitle
      slug {
        current
      }
      seo {
        author
        description
        keywords
        seo_image {
          asset {
            url
          }
        }
      }
      day_section {
        section_title
        day {
          title
          subtitle
          _rawContent
        }
      }
    }
  }
`
